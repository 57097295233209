import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IMarketInfo} from './market-info';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketInfoService {

  private _url: string = 'https://api.tdameritrade.com/v1/marketdata/OPTION/hours?apikey=ROCKETXI';
  constructor(private http: HttpClient) { }

  getMarketInfo(): Observable<IMarketInfo> {
    return this.http.get<IMarketInfo>(this._url);
  }

}
