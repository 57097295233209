import {Component, OnInit} from '@angular/core';
import {MarketInfoService} from '../market-info.service';

@Component({
  selector: 'app-market-info',
  templateUrl: './market-info.component.html',
  styleUrls: ['./market-info.component.css']
})
export class MarketInfoComponent implements OnInit {

  public marketType: string;
  public marketInfo: boolean;
  public marketStatus: string;
  public marketDate: string;
  constructor(private _marketInfo: MarketInfoService) { }

  ngOnInit() {
    this._marketInfo.getMarketInfo().subscribe(
      data => {
        this.marketType = data.option.option.marketType.toLowerCase(),
        this.marketInfo = data.option.option.isOpen,
        this.marketDate = data.option.option.date; }
      );


    if (this.marketInfo === true) {
      this.marketStatus = 'Open';
    } else {
      this.marketStatus = 'Closed';
    }

  }

}
